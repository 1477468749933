<template>
    <main>
        <!-- Cookie Policy Modal -->
        <div id="cookieModal" class="modal fade" :class="{ show : cookiepolicy }" tabindex="-1" role="dialog" :style="{ display: cookiepolicy ? 'block' : 'none' }" style="background-color: rgba(0, 0, 0, 0.5);" data-bs-backdrop="static">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div 
              class="modal-content" 
              style="border-radius: 0; padding: 20px; background-color: #ffffff; clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%);"
            >
              <div class="modal-body text-center">
                <p style="font-size: 16px; color: #333333;">
                  This website uses essential cookies to enhance your experience and provide you with personalized information.
                  By clicking "Accept All", you agree to our use of cookies as outlined in our <button type="button" @click="openCookiePolicy" style="border: 0px; padding: 0px; color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1)); background: none;" data-toggle="modal" data-target="#exampleModalCenter">Cookie Policy</button>.
                </p>
              </div>
              <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-success" style="background-color: #75795e; border: none; padding: 10px 20px; width: 300px; margin-top: 12px;" @click="acceptCookie">ACCEPT ALL</button>
                <button type="button" class="btn btn-secondary" style="background-color: #75795e; border: none; padding: 10px 20px; width: 300px; margin-top: 12px;" @click="closeModal">REJECT ALL</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Cookie Policy Details Modal -->
        <div
          id="policyDetailsModal" 
          class="modal fade" 
          :class="{ show: cookiePolicyDetails }" 
          tabindex="-1" 
          role="dialog" 
          data-bs-backdrop="static" 
          :style="{ display: cookiePolicyDetails ? 'block' : 'none' }" 
          style="background-color: rgba(0, 0, 0, 0.5); color: rgb(51, 51, 51); -bs-modal-width: 70%;"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" style="width: 100%;">Cookie Policy Details</h5>
                <button 
                  type="button" 
                  class="close"
                  style="border: 0px; font-size: 18px; background: none; color: rgb(51, 51, 51)" 
                  @click="closeCookiePolicyDetails"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="columns: 2;">
                <p><strong><em>What are cookies?</em></strong><br>Cookies are small text file stored on your computer or mobile device when visiting our website.</p>
                <p><strong><em>Why we use cookies</em></strong><br>We use cookies to ensure that we give you the best experience on our website. Our website uses cookies, which could include also third party cookies, to send advertising that is relevant to you.</p>
                <p><strong><em>Are all cookies the same?</em></strong>
                  <br>Cookie can be classified as it follows.
                  <br><em>Session cookies. </em>These cookies are automatically erased when you close your browser.
                  <br><em>Persistent cookies.</em> These cookies remain on your device until they expire (in terms of minutes, days or years since the cookie creation/update)
                  <br><em>Third-Party cookies.</em> These cookies are stored on behalf of third parties.
                  <br>Cookies can be managed and deleted by setting your browser. However, this may prevent you from properly use certain features on our website.&nbsp;
                  <br>For more information please visit <a href="http://www.aboutcookies.org" target="_blank" style="color:rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))">www.aboutcookies.org</a> or <a href="http://www.allaboutcookies.org" target="_blank" style="color:rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))">www.allaboutcookies.org</a>.
                  <br><br><em><strong>How can you contact</strong> <strong>us?<br></strong></em>The contact details of the controller and the contract details of the data protection officer can be found in the Privacy Policy of our website.
                </p>
                <p><em><strong>How to know more about processing of personal data in the website</strong></em><br>To find out more about the processing of personal data you can access to the website Privacy policy.</p>
                <p><strong><em>How to manage cookies using our website<br></em></strong>To give you the best experience on the cookie management side, we classify cookies of this website in four categories, based on their purpose: technically necessary, comfort, performance, advertising.&nbsp;&nbsp;</p>
                <p>You can enable and disable directly from this website each of the above cookie category (with the only exception of technically necessary cookies, which are strictly necessary).&nbsp; In case of Third-Party cookies, this website will not use them after disabling (we cannot delete them).</p>
                <p><strong>Technically necessary</strong>: These cookies are essential for websites and their features to work properly. &nbsp;E.g.: authentication cookies.</p>
                <p><strong>Comfort</strong>: These cookies enable us to improve comfort and usability of websites and to provide various features. E.g.: functionality cookies can be used to store search results, language, character dimensions.</p>
                <p><strong>Performance</strong>: These cookies collect information about how you use websites. Performance cookies help us, for example, to identify especially popular areas of our website. In this way, we can adapt the content of our websites more specifically to your needs.</p>
                <p><strong>Advertising</strong>: These cookies are used to send advertising and promotional information that is relevant to you, e.g. based on the web pages you visited.</p>
              </div>
            </div>
          </div>
        </div>

      <section id="landing" class="container-fluid">
        <div class="container">
          <div class="ps-5 mb-5 d-none d-md-block">
            <img src="@/assets/img/heading-home_1.png" alt="Peugeot" width="500" class="img-fluid animation-up">
          </div>
          <div class="d-md-none animation-left">
            <img src="@/assets/img/weekender-landtrek.png" alt="Peugeot Weekender Experience - Landtrek Edition" class="img-fluid mb-3 mb-sm-0">
          </div >
        </div>
  
        <div class="row align-items-end animation-left">
          <div class="col-md-5 col-lg-4 col-xl-3">
            <img src="@/assets/img/weekender-landtrek.png" alt="Peugeot Weekender Experience - Landtrek Edition" class="img-fluid mb-3 mb-sm-0 d-none d-md-block">
          </div>
          <div class="col-md-7 col-lg-6 text-center text-md-start">
            <h6 class="fw-light fs-5 food-nature">URBAN ESCAPES. <br>CULTURAL CRAWLS. FOOD HAVENS.</h6>
            Test drive our vehicles for a weekend to discover the world through the lens of allure.

            <!-- <img src="@/assets/img/everypath.png" alt="Every path a pleasure" class="img-fluid mt-3 d-md-none" width="440"> -->

          </div>
        </div>

        <div class="animate__animated animate__fadeIn animate__faster scroll-down">
          <img class="" src="@/assets/img/i-double-arrow-down.svg">
        </div>

      </section>

      <div id="home-path">
        <section id="path" class="container-fluid">
          <div class="container text-center">
            <h2 class="h1 animation-up">WHERE DOES YOUR NEXT ADVENTURE BEGIN?</h2>
            <h4 class="animation-up delay-0-5s">Experience the thrill of the open road for an entire weekend. Because allure is meant to be savoured.</h4 >
  
            <div class="path-slider my-md-5 animation-up delay-1s">
              <div class="path-selection">
                <div v-for="(item, index) in pathItems" 
                     :key="index" 
                     :class="{ 'slick-current': index === currentIndex }"
                     @mouseenter="handleMouseEnter(index)">
                  <a :href="'/path?route=' + (index + 1)">
                    <img :src="item.src" :alt="item.alt">
                  </a>
                </div>
              </div>
              <div class="slider-controls">
                <button class="btn btn-link control-prev" @click="prevSlide"><img src="@/assets/img/i-prev.svg"></button>
                <button class="btn btn-link control-next" @click="nextSlide"><img src="@/assets/img/i-next.svg"></button>
              </div>
            </div >
  
            <h4 class="animation-up delay-1-5s"><b class="fw-bold">Need more inspiration?</b> Discover your path to pleasure with our AI planner. </h4 >
  
            <div class="chatgpt-initiate my-2 my-md-4 animation-up delay-2s">
              <form @submit.prevent="submitForm">
                <div class="input-group">
                  <input class="form-control form-control-lg font-italic" type="text" v-model="userMessage" placeholder="What do you enjoy? State your starting point." id="prompt" autocomplete="off">
                  <button class="btn btn-light" type="submit" id="button-addon2">
                    <img src="@/assets/img/icon-search.svg" alt="Search" width="30" class="">
                  </button>
                </div>
                <div class="form-text text-light mb-3 font-italic">
                  Eg: Nature. PJ | Photography. Johor | Family activities. Kuantan
                </div>
              </form>
            </div >
  
            <img src="@/assets/img/powered-chatgpt.svg" alt="Powered by ChatGPT" width="300" class="powered-chatgpt img-fluid animation-up delay-2s">
          </div>
        </section>
        <!-- <section id="watch" class="container-fluid">
          <div class="container-md text-center">
            <h2 class="h1 animation-up">PLEASURE IS IN THE DETAILS</h2>
            <h4 class="animation-up delay-0-5s">
              From its impressive exterior to a comfortable interior, <br class="d-none d-md-block">
              discover everything the Landtrek has to offer for your weekend escapades.</h4 >

              <div class="animation-up delay-1s video-container">
                <div class="ratio ratio-16x9"> -->
                  <!-- <div id="play_button" class="stop"></div>
                  <video id="video" playsinline loop muted poster="@/assets/img/poster.jpg" >
                    <source src="@/assets/vid/sample.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                  </video > -->
                 <!--  <iframe src="https://www.youtube.com/embed/2r5fLv_HGxQ?rel=controls=0" title="YouTube video" allowfullscreen></iframe>
                </div>
              </div>

              <a href="https://www.peugeot.com.my/models/landtrek.html" target="_blank" class="animation-up delay-1-5s btn btn-warning d-inline-flex align-items-center justify-content-between gap-3">Discover the Landtrek</a>

          </div>
        </section> -->
        <section id="summary" class="container-fluid text-start">
          <div class="container-md">

            <h2 class="animation-left">Capture The Captivating</h2 >
            <div class="animation-up delay-0-5s">
              <h3>Upload your moments with the hashtags</h3 >
              <div class="hashtag">#PEUGEOTWEEKENDEREXPERIENCE</div>
              <div class="hashtag">#PeugeotMalaysia </div>
              <h3>for a chance to win.</h3>
              <button type="button" @click="openChanceToWin" class="animation-up btn btn-warning d-inline-flex align-items-center justify-content-between" style="padding-right: 12px; padding-left: 12px; min-width: 150px;">HOW TO JOIN</button>
            </div>
    
            <div class="hashtag-gallery px-sm-5 pe-md-4 ps-md-5">
              <div class="position-relative animation-up delay-1s">
                <div class="gallery">
                  <div class="slide"><img src="@/assets/img/gallery/gallery-1.jpg"></div>
                  <div class="slide"><img src="@/assets/img/gallery/gallery-2.jpg"></div>
                  <div class="slide"><img src="@/assets/img/gallery/gallery-3.jpg"></div>
                  <div class="slide"><img src="@/assets/img/gallery/gallery-4.jpg"></div>
                  <div class="slide"><img src="@/assets/img/gallery/gallery-5.jpg"></div>
                </div>
                <div class="slider-controls">
                    <button class="btn btn-link control-prev"><img src="@/assets/img/i-prev.svg"></button>
                    <button class="btn btn-link control-next"><img src="@/assets/img/i-next.svg"></button>
                  </div>
    
              </div>
    
            </div>
            <div class="d-block d-md-flex ps-md-5 pe-md-4 justify-content-between mt-4 mb-4">
              <h4 class="animation-left delay-1s">BOOK YOUR WEEKEND <span class="d-inline-block">TEST DRIVE</span></h4 >
              <a href="/book" class="animation-up delay-1-5s btn btn-warning d-inline-flex align-items-center justify-content-between" style="padding-right: 12px; padding-left: 35px">Let's Go <img src="@/assets/img/button-arrow.png" height="15"></a>
            </div>
          </div>
        </section>

        <!-- How To Join Modal -->
        <div id="chanceToWinModal" class="modal fade" :class="{ show : chanceToWin }" tabindex="-1" role="dialog" :style="{ display: chanceToWin ? 'block' : 'none' }" style="background-color: rgba(0, 0, 0, 0.5);" data-bs-backdrop="static">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header" style="color: #333333;">
                <h5 class="modal-title" style="width: 100%;">HOW TO JOIN:</h5>
                <button 
                  type="button" 
                  class="close"
                  style="border: 0px; font-size: 18px; background: none; color: rgb(51, 51, 51)" 
                  @click="closeChanceToWin"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="font-size: 12px; color: #333333;">
                  <p class="mb-0">1. Take part in the Peugeot Weekender Experience by test driving any of our Peugeot cars.</p>
                  <p class="mb-0">2. Take a photo featuring your Peugeot.</p>
                  <p class="mb-0">3. Upload your photo on your social media accounts and write about your Weekender Experience in the caption.</p>
                  <p class="mb-0">4. Add #PeugeotWeekenderExperience and #PeugeotMalaysia in your caption.</p>
                  <p class="mb-0">5. Tag @peugeotmalaysia in your post.</p>
                  <p class="mb-0">6. Ensure your profile is set to public.</p>
                  <p class="mb-0">7. One (1) winner will be selected each month to win exclusive prizes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      
    </main>
  </template>

  <script>
import { onMounted, ref, onUnmounted } from 'vue';
import $ from 'jquery';
import 'slick-carousel';

export default {
  name: 'HomePage',
  setup() {
    const pathItems = ref([
      { src: require('@/assets/img/1-misty.jpg'), alt: 'Misty Mountains' },
      { src: require('@/assets/img/2-nature.jpg'), alt: 'Nature Whispers' },
      { src: require('@/assets/img/3-culinary.jpg'), alt: 'A Culinary Cruise' },
      { src: require('@/assets/img/4-cultural.jpg'), alt: 'A Cultural Retreat' },
      { src: require('@/assets/img/5-urban.jpg'), alt: 'Urban Relaxation' }
    ]);
    const currentIndex = ref(0);
    const isSlickInitialized = ref(false);
    const windowWidth = ref(window.innerWidth);
    const cookiepolicy = ref(true);
    const cookiePolicyDetails = ref(false);
    const chanceToWin = ref(false);

    onMounted(() => {
      initSlider();
      window.addEventListener('resize', handleResize);

      // Add the video play/pause functionality
      // var video = document.getElementById("video");
      // var playButton = document.getElementById("play_button");
      // playButton.addEventListener("click", function() {
      //   if (video.paused == true) {
      //     video.play();
      //     playButton.classList.toggle("stop");
      //   } else {
      //     video.pause();
      //     playButton.classList.toggle("stop");
      //   }
      // });
    });

    onUnmounted(() => {
      destroySlider();
      window.removeEventListener('resize', handleResize);
    });

    const initSlider = () => {
      if (windowWidth.value < 992 && !isSlickInitialized.value) {
        $('.path-selection').slick({
          slidesToShow: 5,
          slidesToScroll: 1,
          dots: false,
          infinite: true,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
                centerMode: true,
                centerPadding: '0',
                nextArrow: '.control-next',
                prevArrow: '.control-prev',
              }
            },
            // {
            //   breakpoint: 767,
            //   settings: {
            //     slidesToShow: 3,
            //     slidesToScroll: 1,
            //     centerMode: true,
            //     dots: true,
            //     centerPadding: '40px',
            //     touchThreshold: 10,
            //   }
            // },
            // {
            //   breakpoint: 575,
            //   settings: {
            //     slidesToShow: 1,
            //     slidesToScroll: 1,
            //     centerMode: true,
            //     dots: true,
            //     centerPadding: '20%',
            //     touchThreshold: 10,
            //   }
            // }
          ]
        }).on('init', () => {
          $('.path-selection .slick-slide').removeClass('slick-current');
        }).on('afterChange', (event, slick, currentSlide) => {
          currentIndex.value = currentSlide;
        });
        isSlickInitialized.value = true;
      }
    };

    const destroySlider = () => {
      if (isSlickInitialized.value) {
        $('.path-selection').slick('unslick');
        isSlickInitialized.value = false;
      }
    };

    const handleResize = () => {
      const newWindowWidth = window.innerWidth;
      if (newWindowWidth >= 992 && windowWidth.value < 992) {
        destroySlider();
      } else if (newWindowWidth < 992 && windowWidth.value >= 992) {
        initSlider();
      }
      windowWidth.value = newWindowWidth;
    };

    const selectItem = (index) => {
      if (index !== currentIndex.value) {
        if (isSlickInitialized.value) {
          $('.path-selection').slick('slickGoTo', index);
        } else {
          currentIndex.value = index;
        }
      }
    };

    const prevSlide = () => {
      if (isSlickInitialized.value) {
        $('.path-selection').slick('slickPrev');
      } else {
        currentIndex.value = (currentIndex.value - 1 + pathItems.value.length) % pathItems.value.length;
      }
    };

    const nextSlide = () => {
      if (isSlickInitialized.value) {
        $('.path-selection').slick('slickNext');
      } else {
        currentIndex.value = (currentIndex.value + 1) % pathItems.value.length;
      }
    };

    const handleMouseEnter = (index) => {
      currentIndex.value = index;
    };

    return {
      pathItems,
      currentIndex,
      selectItem,
      prevSlide,
      nextSlide,
      isSlickInitialized,
      handleMouseEnter,
      cookiepolicy,
      cookiePolicyDetails,
      chanceToWin
    };
  },
  mounted() {
    this.scrollToSection();
    this.$nextTick(() => {
        this.initializeSlickCarousel();
    });

    const cookieToken = this.$cookies.get('cookie_id');
      if (cookieToken) {
        this.cookiepolicy = false;
      } else {
        this.cookiepolicy = true;
      }

  },
  methods: {
    scrollToSection() {
      const hash = this.$route.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    initializeSlickCarousel() {
      this.destroySlickCarousel();
      $('.gallery').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        nextArrow: '.control-next',
        prevArrow: '.control-prev',
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
                dots: true,
                arrows: true,
              }
            }
          ]
      });
    },
    destroySlickCarousel() {
        if ($('.gallery').hasClass('slick-initialized')) {
          $('.gallery').slick('unslick');
        }
    },
    setupScrollObserver() {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 1.0
        };

        new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.$nextTick(() => {
                this.reinitializeSlickCarousel();
              });
            } 
          });
        }, options);

    },
    reinitializeSlickCarousel() {
      this.destroySlickCarousel();
      this.$nextTick(() => {
        this.initializeSlickCarousel();
      });
    },
    submitForm() {
      // Navigate to /ai-planner with the user message
      this.$router.push({ path: '/ai-planner', query: { message: this.userMessage } });
    },
    closeModal() {
      this.cookiepolicy = false;
    },
    async acceptCookie() {
        const response = await fetch('https://zrmj554iy0.execute-api.ap-southeast-1.amazonaws.com/production/cookie', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors'
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.$cookies.set('cookie_id', data.cookie_id);
        this.cookiepolicy = false;
    },
    openCookiePolicy() {
      this.cookiePolicyDetails = true;
    },
    closeCookiePolicyDetails() {
      this.cookiePolicyDetails = false;
    },
    openChanceToWin() {
      this.chanceToWin = true;
    },
    closeChanceToWin() {
      this.chanceToWin = false;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$nextTick(() => {
        vm.initializeSlickCarousel();
      });
    });
  },
  beforeRouteUpdate(to, from, next) {
      this.$nextTick(() => {
      this.initializeSlickCarousel();
      });
    next();
  },
  beforeUnmount() {
      this.destroySlickCarousel();
  },
  data() {
    return {
      userMessage: '', // Store user input
    };
  },
};
</script>
