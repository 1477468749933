<template>
    <main id="" class="container animate__animated animate__fadeInRight animate__faster">
      <div class="row">
        <div class="col-lg-6">
          <h1>BOOK YOUR<br class="d-none d-lg-block">
          WEEKEND<br class="d-none d-lg-block">
          TEST DRIVE</h1>

          <p>Ready to get behind the wheel?</p>
          <a class="tnc mb-3" href="https://www.peugeot.com.my/tools/terms-condition.html">Terms and conditions apply.</a>
        </div>
        <div class="col-lg-6">
            <div class="form-box">
              <iframe src="https://cloud.iap-crm.stellantis.com/MY_TestDrive" frameborder="0" marginwidth="0" marginheight="0"></iframe>
            </div>
        </div>
      </div>
    </main >
  </template>
  
  <script>
  export default {
    name: 'ContactPage',
  };
  </script>